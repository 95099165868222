import { useEffect, useState } from "react";

import { Box, Grid, Link, Typography } from "@mui/material";
import AboutProjetPage from "./components/aboutprojectpage";
import { NavLink, useNavigate } from "react-router-dom";
import Colors from "common/const/colorzulu";
import ZuluButton from "common/components/zulubutton";
import FontFamilyName from "common/const/fontfamily";
import { slice } from "lodash";
import { ProjectInterface } from "model/interfaces/project";
import Loader from "common/components/Loader";
import ProjectList from "common/components/project_list";
import { ApiController } from "common/api/api_controller";
import { AboutPageInterface } from "model/interfaces/about_page";

function ProjectPage() {
  const navigate = useNavigate();
  const [data, setData] = useState<ProjectInterface[]>([]);
  const [index, setIndex] = useState(10);
  const initialData = slice(data, 0, index);
  const [isCompleted, setCompleted] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const [dataAbout, setDataAbout] = useState<AboutPageInterface | null>(null);

  const loadMore = () => {
    if (!isCompleted) {
      setLoading(true);
      setIndex(index + 10);
      // console.log(index);

      if (index + 10 >= data.length) {
        setCompleted(true);
      } else {
        setCompleted(false);
      }
      setLoading(false);
    }

    //
  };

  // function getData() {
  //   setLoading(true);

  //   setData(projectDummy);
  // }

  async function getData() {
    setLoading(true);
    let respon = await ApiController.getAllProject();
    if (index >= respon.length) {
      setCompleted(true);
    } else {
      setCompleted(false);
    }
    setData([...respon]);
  }

  async function getDataAbout() {
    setLoading(true);
    let respon = await ApiController.getAboutProject();

    setDataAbout({ ...respon });
  }

  useEffect(() => {
    getData();
    getDataAbout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoading(false);
    // console.log(data);
  }, [data]);

  return (
    <>
      {/* <Header />
      <SpaceingAppBar /> */}
      <Box sx={{ height: { xs: "40px", lg: "142px" } }} />
      <AboutProjetPage dataAbout={dataAbout} />

      {isLoading ? <Loader /> : <></>}

      <Box sx={{ height: { xs: "40px", lg: "142px" } }} />

      {initialData.map((item, i) => (
        <Link
          key={i}
          component={NavLink}
          to={"/project/" + item.pathUrl}
          color="black"
          underline="none"
          variant="button"
        >
          {item.imageCover != null ? (
            <ProjectList
              topDevider={i === 0 ? false : true}
              key={i}
              client={item.client}
              projectName={item.projectName}
              year={item.year.toString()}
              arrImages={item.imageCover}
              bgColor={Colors.secondayWhite}
              textColor={Colors.textGrey}
              btnColor={"info"}
              isLarge={i % 2 === 0 ? true : false}
              onClick={() => {
                navigate("/project/" + item.pathUrl);
              }}
            />
          ) : (
            <></>
          )}
        </Link>
      ))}

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          // pb: { xs: "36px", lg: "56px" },
          pt: { xs: "23px", lg: "46px" },
          // backgroundColor: Colors.primaryRed,
        }}
      >
        {isCompleted ? (
          <></>
        ) : (
          <ZuluButton
            onClick={() => {
              loadMore();
              // alert("clicked");
            }}
            color={"info"}
            width={{ xs: "118px", lg: "232px" }}
            height={{ xs: "25px", lg: "50px" }}
          >
            <Typography
              sx={{
                fontFamily: FontFamilyName.Akkurat,
                fontSize: { xs: "11px", lg: "16px" },
                fontWeight: 400,
                // px: { xs: "22.5px", lg: "62.5px" },
                // py: { xs: "5.5px", lg: "14.5px" },
                color: Colors.textGrey,
              }}
            >
              Load More
            </Typography>
          </ZuluButton>
        )}
      </Grid>

      {/* <Footer /> */}
    </>
  );
}

export default ProjectPage;
