import { Box, Divider } from "@mui/material";
import BodyAbout from "./components/bodyabout";

import ClientAbout from "./components/clientabout";
import { AboutInterface } from "model/interfaces/about";
import { useEffect, useState } from "react";
import { ApiController } from "common/api/api_controller";

const About = () => {
  const [data, setData] = useState<AboutInterface | null>(null);
  const [isLoading, setLoading] = useState(false);

  async function getData() {
    setLoading(true);
    let respon = await ApiController.getAbout();

    setData({ ...respon });
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setLoading(false);
    // console.log(data);
  }, [data]);
  return (
    <>
      {/* <Header />
      <SpaceingAppBar /> */}
      <BodyAbout data={data} />

      <Box height={{ xs: "20px", lg: "164px" }} />
      <Divider variant="fullWidth" orientation="horizontal" />
      <ClientAbout />

      {/* <Divider variant="fullWidth" orientation="horizontal" /> */}
      <Box height={{ xs: "10px", md: "80px" }} />
      {/* <TeamAbout data={dataTeam!} /> */}

      {/* <Footer /> */}
    </>
  );
};

export default About;
