import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Colors, FontFamilyName } from "common/const/const_config";
import { AboutPageInterface } from "model/interfaces/about_page";

const AboutProjetPage = (props: { dataAbout?: AboutPageInterface | null }) => {
  const { dataAbout } = props;
  return (
    <>
      {/* <Container maxWidth={"xl"}> */}
      <Grid
        container
        direction="row"
        // justifyContent={"center"}
        // justifyItems={"center"}
        pl={{ xs: "22px", lg: "71px" }}
        // sx={{ pt: { xs: "144px", lg: "142px" } }}
      >
        <Grid item xs={12} lg={6}>
          <Typography
            sx={{
              fontFamily: FontFamilyName.BasisGrotesquePro,
              color: Colors.textGrey,
              fontSize: { xs: "28px", lg: "72px" },
              fontWeight: 500,
              pt: { xs: "none", lg: "20px" },
              // pl: { xs: "22px", lg: "71px" },
            }}
          >
            Project Zulu
          </Typography>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Typography
            sx={{
              fontFamily: FontFamilyName.Akkurat,
              color: Colors.textGrey,
              fontSize: { xs: "11px", lg: "16px" },
              fontWeight: 400,
              // pl: { xs: "22px", lg: "71px" },
              pr: { xs: "134px", lg: "182px" },
              pt: { xs: "39px", lg: "0" },
              lineHeight: { xs: "14px", lg: "21px" },
            }}
          >
            {dataAbout?.desc ?? ``}
          </Typography>
        </Grid>
      </Grid>
      {/* </Container> */}
    </>
  );
};

export default AboutProjetPage;
