import {
  Alert,
  Box,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import AxiosApi from "common/api/axios";
import ZuluButton from "common/components/zulubutton";
import { Colors, FontFamilyName } from "common/const/const_config";
import { ApiConfigInterface } from "model/interfaces/api_config";
import { ContactUsInterface } from "model/interfaces/contact_us";
import { useState } from "react";

interface Dataset {
  errorMessage: string;
  error: boolean;
  value: string;
}

interface formInterface {
  [key: string]: Dataset;
}

// interface formInterface{
//     name: Name
//     email: Name1
//     phone:
// }

const ContactFormMobile = () => {
  // const [formValues, setFormValues] = useState<Dataset[]>(['adasd':{value:'',name:{}}])
  const [openSnackBar, setOpenSnackbar] = useState(false);
  const [dataSnackbar, setDataSnackbar] = useState<any>(null);
  const [formValues, setFormValues] = useState<formInterface>({
    name: {
      value: "",
      error: false,
      errorMessage: "You must enter a name",
    },
    email: {
      value: "",
      error: false,
      errorMessage: "You must enter a email",
    },
    phone: {
      value: "",
      error: false,
      errorMessage: "You must enter a phone",
    },
    company: {
      value: "",
      error: false,
      errorMessage: "You must enter a company",
    },
    message: {
      value: "",
      error: false,
      errorMessage: "You must enter a message",
    },
  });
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormValues({
      ...formValues,
      [name]: {
        ...formValues[name],
        value: value,
      },
    });
  };

  const emailValidation = (val: string): boolean => {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!val || regex.test(val) === false) {
      return true;
    }
    return false;
  };

  //   const phoneValidation = (val: string): boolean => {
  //     const regex = new RegExp(/^((\\+)|(00)|(\\*)|())[0-9]{3,14}((\\#)|())$/);
  //     if (!val || regex.test(val) === false) {
  //       return true;
  //     }
  //     return false;
  //   };

  function validationEmpty(val: string): boolean {
    return val === "";
  }

  const handleSubmit = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const formFields = Object.keys(formValues);
    let newFormValues = { ...formValues };
    let validation = true;

    for (let index = 0; index < formFields.length; index++) {
      const currentField: string = formFields[index];
      const currentValue: string = formValues[currentField].value;
      // console.log(currentField);
      // console.log(currentValue === "");
      switch (currentField) {
        case "email":
          newFormValues = {
            ...newFormValues,
            [currentField]: {
              ...newFormValues[currentField],
              error: emailValidation(currentValue),
              errorMessage: "Email is not valid",
            },
          };
          if (emailValidation(currentValue)) validation = false;
          break;
        // case "phone":
        //   newFormValues = {
        //     ...newFormValues,
        //     [currentField]: {
        //       ...newFormValues[currentField],
        //       error: phoneValidation(currentValue),
        //       errorMessage: "Phone Number is not valid",
        //     },
        //   };
        //   break;

        default:
          newFormValues = {
            ...newFormValues,
            [currentField]: {
              ...newFormValues[currentField],
              error: validationEmpty(currentValue),
            },
          };
          if (validationEmpty(currentValue)) validation = false;
          break;
      }
    }

    setFormValues(newFormValues);
    if (validation) sendData(newFormValues);
  };
  const sendData = async (values: any) => {
    let data: ContactUsInterface = {
      company: values["company"].value,
      email: values["email"].value,
      message: values["message"].value,
      phone: values["phone"].value,
      name: values["name"].value,
    };

    let configAxios: ApiConfigInterface = {
      method: "post",
      body: data,
      path: "/v1/contact/us",
    };

    let respon = await AxiosApi.call(configAxios);
    if (respon?.success) {
      let dataSnackbar = {
        message: respon?.result?.message ?? "",
        color: "success",
      };

      setDataSnackbar({ ...dataSnackbar });
      setOpenSnackbar(true);
      resetForm();
    } else {
      let dataSnackbar = {
        message: "something went wrong :(",
        color: "error",
      };

      setDataSnackbar({ ...dataSnackbar });
      setOpenSnackbar(true);
    }
    // console.log(respon);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const resetForm = () => {
    let dataDefault = {
      name: {
        value: "",
        error: false,
        errorMessage: "You must enter a name",
      },
      email: {
        value: "",
        error: false,
        errorMessage: "You must enter a email",
      },
      phone: {
        value: "",
        error: false,
        errorMessage: "You must enter a phone",
      },
      company: {
        value: "",
        error: false,
        errorMessage: "You must enter a company",
      },
      message: {
        value: "",
        error: false,
        errorMessage: "You must enter a message",
      },
    };
    setFormValues({ ...dataDefault });
  };

  return (
    <Box
      component="form"
      // sx={{
      //   "& .MuiTextField-root": { m: 1 },
      // }}
      noValidate
      autoComplete="off"
    >
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={dataSnackbar?.color}
          sx={{ width: "100%" }}
        >
          {dataSnackbar?.message}
        </Alert>
      </Snackbar>
      <Grid container direction={"row"} spacing={"30px"}>
        <Grid item xs={12} lg={6}>
          <TextField
            name="name"
            label="Name"
            variant="standard"
            fullWidth
            onChange={handleChange}
            value={formValues.name.value}
            error={formValues.name.error}
            helperText={formValues.name.error && formValues.name.errorMessage}
            FormHelperTextProps={
              {
                // style: { fontSize: "20px", lineHeight: "20px" },
              }
            }
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            name="email"
            label="Email"
            variant="standard"
            fullWidth
            onChange={handleChange}
            value={formValues.email.value}
            error={formValues.email.error}
            helperText={formValues.email.error && formValues.email.errorMessage}
            FormHelperTextProps={
              {
                // style: { fontSize: "20px", lineHeight: "20px" },
              }
            }
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            name="company"
            label="Company"
            variant="standard"
            fullWidth
            onChange={handleChange}
            value={formValues.company.value}
            error={formValues.company.error}
            helperText={
              formValues.company.error && formValues.company.errorMessage
            }
            FormHelperTextProps={
              {
                // style: { fontSize: "20px", lineHeight: "20px" },
              }
            }
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            name="phone"
            label="Phone"
            variant="standard"
            fullWidth
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            onChange={handleChange}
            value={formValues.phone.value}
            error={formValues.phone.error}
            helperText={formValues.phone.error && formValues.phone.errorMessage}
            FormHelperTextProps={
              {
                // style: { fontSize: "20px", lineHeight: "20px" },
              }
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="message"
            label="Message"
            multiline
            rows={4}
            variant="standard"
            fullWidth
            onChange={handleChange}
            value={formValues.message.value}
            error={formValues.message.error}
            helperText={
              formValues.message.error && formValues.message.errorMessage
            }
            FormHelperTextProps={
              {
                // style: { fontSize: "20px", lineHeight: "20px" },
              }
            }
          />
        </Grid>
        <Grid item xs={12}>
          <ZuluButton onClick={handleSubmit} color={"info"}>
            <Typography
              sx={{
                color: Colors.textGrey,
                fontFamily: FontFamilyName.Akkurat,
                fontSize: { xs: "11px", lg: "16px" },
                fontWeight: 400,
                px: { xs: "10px", lg: "70px" },
                py: { xs: "0px", lg: "5px" },
              }}
            >
              Submit
            </Typography>
          </ZuluButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactFormMobile;
