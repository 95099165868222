import { FontFamilyName, Colors } from "common/const/const_config";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import ZuluButton from "common/components/zulubutton";
import { useEffect, useState } from "react";
import CardProduct from "common/components/cardProduct";
import { useNavigate } from "react-router-dom";
import { ProductInterface } from "model/interfaces/product";
import { slice } from "lodash";
import { ApiController } from "common/api/api_controller";

const OurProduct = (props: any) => {
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [data, setData] = useState<ProductInterface[]>([]);

  const initialData = slice(data, 0, isMobile ? 3 : 4);
  const [isLoading, setLoading] = useState(false);

  // function getData() {
  //   setLoading(true);
  //   setData(productDummy);
  // }

  async function getData() {
    setLoading(true);
    let respon = await ApiController.getAllProduct();

    setData([...respon]);
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [data]);

  // useEffect(() => {
  //   window.addEventListener("error", (e) => {
  //     if (e.message === "ResizeObserver loop limit exceeded") {
  //       const resizeObserverErrDiv = document.getElementById(
  //         "webpack-dev-server-client-overlay-div"
  //       );
  //       const resizeObserverErr = document.getElementById(
  //         "webpack-dev-server-client-overlay"
  //       );
  //       if (resizeObserverErr) {
  //         resizeObserverErr.setAttribute("style", "display: none");
  //       }
  //       if (resizeObserverErrDiv) {
  //         resizeObserverErrDiv.setAttribute("style", "display: none");
  //       }
  //     }
  //   });
  // }, []);

  return (
    // <Container maxWidth={"xl"}>
    <Grid
      container
      direction="row"
      // justifyContent="flex-start"
      // alignItems="stretch"
      justifyContent="flex-start"
      alignItems="flex-start"
      columnSpacing={"50px"}
      rowSpacing={"40px"}
      sx={{
        px: { xs: "21px", lg: "72px" },
      }}
    >
      <Grid item xs={12}>
        <Typography
          sx={{
            color: Colors.textGrey,
            pb: { xs: "16px", lg: "0" },
            pt: { xs: "30px", lg: "75px" },
            fontSize: { xs: "28px", lg: "48px" },
            fontFamily: FontFamilyName.BasisGrotesquePro,
            fontWeight: { xs: 500, lg: 400 },
          }}
        >
          Our Product
        </Typography>
        <Box height={"20px"} />
      </Grid>

      {initialData?.map((item, index) => (
        <Grid
          item
          xs={12}
          md={6}
          key={index}
          // maxHeight={"496px"}
          // maxWidth={"650px"}
        >
          {item.imageCover != null ? (
            <CardProduct
              title={item.productName}
              year={item.year}
              isNew={item.isNew}
              image={item.imageCover[0]}
              link={() => {
                navigate("/product/" + item.productName.toPathUrl());
              }}
              maxWidthCard={"650px"}
              maxHeightCard={"496px"}
              fontSizeTitle={{ xs: "24px", lg: "32px" }}
              lineHeightTitle={{ xs: "30px", lg: "38px" }}
              heightImage={390.95}
              widthImage={623}
            />
          ) : (
            <></>
          )}
        </Grid>
      ))}

      <Grid item container justifyContent={"center"}>
        {/* <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          pb: { xs: "36px", lg: "56px" },
          pt: { xs: "23px", lg: "46px" },
          // backgroundColor: Colors.primaryRed,
        }}
      > */}
        <ZuluButton
          onClick={() => {
            navigate("/products");
          }}
          color={"info"}
          height={{ xs: "25px", md: "50px" }}
          width={{ xs: "118px", md: "223px" }}
        >
          <Typography
            sx={{
              fontFamily: FontFamilyName.Akkurat,
              fontSize: { xs: "11px", lg: "16px" },
              fontWeight: 400,
              // px: { xs: "22.5px", lg: "62.5px" },
              // py: { xs: "5.5px", lg: "14.5px" },
              color: Colors.textGrey,
            }}
          >
            Explore More
          </Typography>
        </ZuluButton>
      </Grid>
      <Grid item height={{ xs: "36px", md: "82px" }} />
      {/* </Grid> */}
    </Grid>
    // </Container>
  );

  // return (
  //   <>
  //     <Container maxWidth={"xl"}>
  //       <Typography
  //         sx={{
  //           color: Colors.textGrey,
  //           pb: { xs: "16px", lg: "0" },
  //           pt: { xs: "30px", lg: "75px" },
  //           fontSize: { xs: "28px", lg: "48px" },
  //           fontFamily: FontFamilyName.BasisGrotesquePro,
  //           fontWeight: { xs: 500, lg: 400 },
  //         }}
  //       >
  //         Our Product
  //       </Typography>
  //       <Box height={"20px"} />

  //       <Masonry columns={{ xs: 1, lg: 2 }} spacing={2}>
  //         {/* {heights.map((height, index) => (
  //           <Item key={index} sx={{ height }}>
  //             {index + 1}
  //           </Item>
  //         ))} */}
  //         {/*
  //         <Box
  //           bgcolor="transparent"
  //           sx={{
  //             height: "238px",
  //             display: { xs: "none", lg: "block" },
  //           }}
  //         /> */}

  //         {initialData?.map((item, index) => (
  //           <CardProduct
  //             key={index}
  //             title={item.productName}
  //             year={item.year}
  //             isNew={item.isNew}
  //             image={item.image}
  //             link={() => {
  //               navigate("/product/" + item.productName.toPathUrl());
  //             }}
  //             maxWidthCard={"639px"}
  //           />
  //         ))}

  //         {/* <CardProduct
  //           title={"N95 Mask"}
  //           year={"2021"}
  //           isNew={false}
  //           image={ImageAssets.n95mask}
  //           isNew={false}
  //           image={{
  //             baseUrl: ImageAssets.gloves,
  //             alt: "Gloves",
  //             name: "Gloves",
  //             usingInMobile: false,
  //           }}
  //           link={() => {}}
  //           maxWidthCard={"639px"}
  //         />
  //         <CardProduct
  //           title={"Bluetooth BT 01 Matte Black"}
  //           year={"2021"}
  //           isNew={true}
  //           image={ImageAssets.helm}

  //         />
  //         <CardProduct
  //           title={"Sports Mask"}
  //           year={"2021"}
  //           isNew={true}
  //           image={ImageAssets.sportmask}
  //           isNew={false}
  //           image={{
  //             baseUrl: ImageAssets.gloves,
  //             alt: "Gloves",
  //             name: "Gloves",
  //             usingInMobile: false,
  //           }}
  //           link={() => {}}
  //           maxWidthCard={"639px"}
  //         /> */}
  //       </Masonry>
  //     </Container>

  //     <Grid
  //       container
  //       direction="row"
  //       justifyContent="center"
  //       alignItems="center"
  //       sx={{
  //         pb: { xs: "36px", lg: "56px" },
  //         pt: { xs: "23px", lg: "46px" },
  //         // backgroundColor: Colors.primaryRed,
  //       }}
  //     >
  //       <ZuluButton
  //         onClick={() => {
  //           navigate("/product");
  //         }}
  //         color={"info"}
  //       >
  //         <Typography
  //           sx={{
  //             fontFamily: FontFamilyName.Akkurat,
  //             fontSize: { xs: "11px", lg: "16px" },
  //             fontWeight: 400,
  //             px: { xs: "22.5px", lg: "62.5px" },
  //             py: { xs: "5.5px", lg: "14.5px" },
  //             color: Colors.textGrey,
  //           }}
  //         >
  //           Explore More
  //         </Typography>
  //       </ZuluButton>
  //     </Grid>
  //   </>
  // );
};

OurProduct.propTypes = {};

export default OurProduct;
