import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BodyProductDetail from "./components/bodyproductdetail";
import SlideDiscover from "common/components/slideDiscover";
import { ApiController } from "common/api/api_controller";
import { ProductInterface } from "model/interfaces/product";

const ProductDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState<ProductInterface | null>(null);
  const [dataDiscover, setDataDiscover] = useState<ProductInterface[] | null>(
    null
  );
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    findData();
    getDataDiscover();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const findData = async () => {
    setLoading(true);
    let haveDetail = false;

    let respon = await ApiController.getSelectedProduct(id ?? "");
    haveDetail = respon != null;

    setData({ ...respon });

    if (!haveDetail) navigate("/notfound", { replace: true });
  };

  const getDataDiscover = async () => {
    let respon = await ApiController.getDiscoverProduct(id ?? "");

    setDataDiscover([...respon]);
  };

  return (
    <>
      {/* <Header />
      <SpaceingAppBar /> */}
      <BodyProductDetail data={data ?? null} />
      {/* allProduct={dataProduct} */}
      <Box sx={{ height: { xs: "0px", lg: "69px" } }} />
      <SlideDiscover dataProject={null} dataProduct={dataDiscover} />
      {/* <Footer /> */}
    </>
  );
};

export default ProductDetail;
