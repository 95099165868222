import { Box, Grid, Typography, Link } from "@mui/material";
import ZuluButton from "common/components/zulubutton";
import { Colors, FontFamilyName } from "common/const/const_config";
import { NavLink, useNavigate } from "react-router-dom";
import { slice } from "lodash";
import { ProjectInterface } from "model/interfaces/project";
import { useState, useEffect } from "react";
import "../style/project_hightlight.css";
import ProjectList from "common/components/project_list";
import { ApiController } from "common/api/api_controller";

export default function ProjectHightlight() {
  const navigate = useNavigate();
  const [data, setData] = useState<ProjectInterface[]>([]);

  const initialData = slice(data, 0, 3);
  const [isLoading, setLoading] = useState(false);

  // function getData() {
  //   setLoading(true);
  //   setData(projectDummy);
  // }

  async function getData() {
    setLoading(true);
    let respon = await ApiController.getAllProject();

    setData([...respon]);
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [data]);

  return (
    <>
      <Grid container direction={"column"}>
        <Grid item bgcolor={Colors.primaryRed}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              pl: { xs: "21px", lg: "72px" },
              pr: { xs: "22px", lg: "91px" },
            }}
          >
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  color: Colors.secondayWhite,
                  pb: { xs: "23px", md: "0" },
                  //   pl: { xs: "22px", lg: "71px" },
                  //   width: { xs: "213px", lg: "356px" },
                  fontSize: { xs: "28px", lg: "48px" },
                  //   lineHeight: { xs: "62px", lg: "100px" },
                  fontFamily: FontFamilyName.BasisGrotesquePro,
                  fontWeight: { xs: 500, lg: 400 },
                }}
              >
                Project Highlight
              </Typography>
            </Grid>
            <Grid item>
              <ZuluButton
                onClick={() => {
                  navigate("/projects");
                }}
                color={"secondary"}
              >
                <Typography
                  sx={{
                    fontFamily: FontFamilyName.Akkurat,
                    fontSize: { xs: "11px", lg: "16px" },
                    fontWeight: 400,
                    px: { xs: "20.5px", lg: "71.5px" },
                    py: { xs: "5.5px", lg: "14.5px" },
                  }}
                >
                  Explore More
                </Typography>
              </ZuluButton>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Box
            sx={{
              height: { xs: "23px", lg: "0px" },
              backgroundColor: Colors.primaryRed,
            }}
          />
        </Grid>

        {/* TODO: perbaikan ini dulu sebelum ke yang lain PROJECT */}
        {initialData?.map((item, i) => (
          <Grid item key={i}>
            <Link
              component={NavLink}
              to={"/project/" + item.projectName.toPathUrl()}
              color="black"
              underline="none"
              variant="button"
            >
              {item.imageCover != null ? (
                <ProjectList
                  key={i}
                  client={item.client}
                  projectName={item.projectName}
                  year={item.year.toString()}
                  arrImages={item.imageCover}
                  onClick={() => {
                    navigate("/project/" + item.projectName.toPathUrl());
                  }}
                  isLarge={i % 2 === 0 ? true : false}
                />
              ) : (
                <></>
              )}
            </Link>
          </Grid>
        ))}
      </Grid>

      {/* <Stack direction={"column"}> */}
      {/* <Box
          sx={{
            position: "absolute",
            width: "100%",
            // backgroundColor: Colors.infoBlack,
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            wrap="wrap"
            sx={
              {
                // backgroundColor: Colors.infoBlack,
                // width: { xs: "96.5vw", lg: "98.5vw" },
              }
            }
          >
            <Grid item></Grid>
          </Grid>
        </Box> */}

      {/* <Box
          sx={{
            height: { xs: "147px", lg: "262px" },
          }}
        />
        <Box
          sx={{
            height: { xs: "89px", lg: "203px" },
            backgroundColor: Colors.primaryRed,
          }}
        /> */}

      {/* <Box
        sx={{
          height: { xs: "23px", lg: "67px" },
          backgroundColor: Colors.primaryRed,
        }}
      /> */}

      {/* {initialData.map((item, i) => (
          <Link
            key={i}
            component={NavLink}
            to={"/project/" + item.projectName.toPathUrl()}
            color="black"
            underline="none"
            variant="button"
          >
            <ContainProjectHightlight
              key={i}
              client={item.client}
              projectName={item.projectName}
              year={item.year.toString()}
              arrImages={item.image}
              onClick={() => {
                navigate("/project/" + item.projectName.toPathUrl());
              }}
              isLarge={i % 2 === 0 ? true : false}
            />
          </Link>
        ))} */}

      {/* <Box
        sx={{
          height: { xs: "23px", lg: "67px" },
          backgroundColor: Colors.primaryRed,
        }}
      /> */}
      {/* </Stack> */}
    </>
  );
}
