import { Box, Typography } from "@mui/material";
import Colors from "common/const/colorzulu";
import FontFamilyName from "common/const/fontfamily";
import { ServiceInterface } from "model/interfaces/service";

const AboutService = (props: { data?: ServiceInterface }) => {
  const { data } = props;
  const nameService: string = data?.name ?? "Services";
  const descService: string = data?.desc ?? ``;

  return (
    <>
      {/* <Container maxWidth={"xl"}> */}
      <Typography
        sx={{
          color: Colors.textGrey,
          fontFamily: FontFamilyName.BasisGrotesquePro,
          fontSize: { xs: "24px", md: "72px" },
          fontWeight: 500,
          whiteSpace: "pre-wrap",
          // lineHeight: { xs: "36px", md: "108px" },
        }}
      >
        {nameService}
      </Typography>
      <Box height={{ xs: "20px", md: "80px" }} />
      <Typography
        sx={{
          color: Colors.textGrey,
          fontFamily: FontFamilyName.BasisGrotesquePro,
          fontSize: { xs: "14px", md: "32px" },
          fontWeight: 400,
          // whiteSpace: "pre-wrap",
          pl: { xs: "40px", md: "105px" },
          pr: { xs: "35px", md: "none" },
          width: { xs: "90vw", md: "900px" },

          lineHeight: { xs: "18px", md: "37.73px" },
        }}
      >
        {descService}
      </Typography>

      {/* </Container> */}
    </>
  );
};

export default AboutService;
