import { Grid, Link, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { FontFamilyName, ImageAssets, Colors } from "common/const/const_config";
import { NavLink } from "react-router-dom";

const Page404 = () => {
  return (
    <>
      {/* <Header /> */}
      {/* <Container maxWidth={"xl"}> */}
      {/* <SpaceingAppBar /> */}
      <Grid
        container
        direction="column"
        justifyContent="space-around"
        alignItems="center"
      >
        <Grid item>
          <Box
            component={"img"}
            src={ImageAssets.error404}
            sx={{
              width: { xs: "80vw", md: "30vw", lg: "30vw" },
              maxWidth: "1246px",
            }}
          />
        </Grid>
        <Grid item>
          <Typography
            sx={{
              fontFamily: FontFamilyName.BasisGrotesquePro,
              color: Colors.infoBlack,
              fontSize: { xs: "28px", lg: "42px" },
              fontWeight: 500,
            }}
          >
            Page Not Found
          </Typography>
        </Grid>

        <Grid item>
          <Link component={NavLink} to={"/"}>
            <Typography
              sx={{
                fontFamily: FontFamilyName.Akkurat,
                color: Colors.infoBlack,
                fontSize: { xs: "20px", lg: "30px" },
                fontWeight: 400,
              }}
            >
              Back to Home Page
            </Typography>
          </Link>
        </Grid>
      </Grid>
      {/* </Container> */}
      <Box
        sx={{
          position: "absolute",
          left: 0,
          bottom: 0,
          right: 0,
        }}
      >
        {/* <Footer /> */}
      </Box>
    </>
  );
};

export default Page404;
