import { ImageListItem, useMediaQuery, useTheme } from "@mui/material";
import ZuluConfig from "common/config/zulu_config";
import { ImagesType } from "model/types/imagesType";
import Masonry from "react-responsive-masonry";

const ImageProductDetail = (props: { dataImage: ImagesType[] }) => {
  const { dataImage } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  // const itemData = [
  //   {
  //     img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
  //     title: "Bed",
  //   },
  //   {
  //     img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
  //     title: "Books",
  //   },
  //   {
  //     img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
  //     title: "Sink",
  //   },
  //   {
  //     img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
  //     title: "Kitchen",
  //   },
  //   {
  //     img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
  //     title: "Blinds",
  //   },
  //   {
  //     img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
  //     title: "Chairs",
  //   },
  //   {
  //     img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
  //     title: "Laptop",
  //   },
  //   {
  //     img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
  //     title: "Doors",
  //   },
  //   {
  //     img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
  //     title: "Coffee",
  //   },
  //   {
  //     img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
  //     title: "Storage",
  //   },
  //   {
  //     img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
  //     title: "Candle",
  //   },
  //   {
  //     img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
  //     title: "Coffee table",
  //   },
  // ];

  return (
    // <ResponsiveMasonry>
    <Masonry columnsCount={2} gutter={isMobile ? "10px" : "20px"}>
      {dataImage?.map((item, index) => (
        <ImageListItem key={index}>
          <img
            src={`${encodeURI(ZuluConfig.baseUrlImage + item.baseUrl)}`}
            // srcSet={`${encodeURI(ZuluConfig.baseUrlImage + item.baseUrl)}`}
            alt={item.alt}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </Masonry>
    // </ResponsiveMasonry>
    // <Box sx={{ px: { xs: "22px", lg: "71px" } }}>
    //   <ImageList
    //     // variant="masonry"
    //     gap={isMobile ? 10 : 20}
    //   >
    //     {dataImage?.map((item, index) => (
    //       <ImageListItem key={index}>
    //         <img
    //           src={`${encodeURI(ZuluConfig.baseUrlImage + item.baseUrl)}`}
    //           // srcSet={`${encodeURI(ZuluConfig.baseUrlImage + item.baseUrl)}`}
    //           alt={item.alt}
    //           loading="lazy"
    //         />
    //       </ImageListItem>
    //     ))}
    //     {/* {data?.imageBody.map((item, index) => (
    //       <ImageListItem key={index}>
    //         <img
    //           src={`${item.baseUrl}?w=400&fit=crop&auto=format`}
    //           srcSet={`${item.baseUrl}?w=400&fit=crop&auto=format&dpr=2 2x`}
    //           alt={item.alt}
    //           loading="lazy"
    //         />
    //       </ImageListItem>
    //     ))} */}
    //   </ImageList>
    // </Box>
  );
};

export default ImageProductDetail;
