import {
  Grid,
  Link,
  Typography,
  Box,
  useMediaQuery,
  useTheme,
  Divider,
} from "@mui/material";
import Colors from "common/const/colorzulu";
import { FontFamilyName } from "common/const/const_config";
import { NavLink, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Masonry from "@mui/lab/Masonry";
import { useEffect } from "react";
import SizeBoxHideonxs from "common/components/sizebox";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { ProductDetailInterface2 } from "model/interfaces/detailproduct";
import { ProductInterface, ProductInterface2 } from "model/interfaces/product";
import ImageProductDetail from "./imageProductDetail";
import ZuluConfig from "common/config/zulu_config";

function srcset(image: string, size: number, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

function srcset2(image: string, width: number, height: number) {
  return {
    src: `${image}?w=${width}&h=${height}&fit=crop&auto=format`,
    srcSet: `${image}?w=${width}&h=${height}&fit=crop&auto=format&dpr=2 2x`,
  };
}

const BodyProductDetail = (props: {
  data?: ProductInterface | null;
  // allProduct: ProductInterface2[];
}) => {
  const { data } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const navigate = useNavigate();

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1.25,
    slidesToScroll: 1,
    centerMode: false,
    variableWidth: false,
    swipeToSlide: true,
    edgeFriction: 1,
    arrows: false,
    autoplay: true,
  };

  //   var heightSizeBox: string | null[];
  //   for (let index = 0; index < data?.image?.length; index++) {
  //     const element = data?.image[index];
  //     heightSizeBox.push("asdad");
  //   }
  //   const sizeImage: (string | null)[] = [];
  //   data?.image.forEach(async (item) =>
  //     sizeImage.push(await fetchImageSize(item.baseUrl))
  //   );

  //   useEffect(() => {}, [sizeImage]);

  const DetailProject = () => {
    useEffect(() => {
      window.addEventListener("error", (e) => {
        if (e.message === "ResizeObserver loop limit exceeded") {
          const resizeObserverErrDiv = document.getElementById(
            "webpack-dev-server-client-overlay-div"
          );
          const resizeObserverErr = document.getElementById(
            "webpack-dev-server-client-overlay"
          );
          if (resizeObserverErr) {
            resizeObserverErr.setAttribute("style", "display: none");
          }
          if (resizeObserverErrDiv) {
            resizeObserverErrDiv.setAttribute("style", "display: none");
          }
        }
      });
    }, []);

    return (
      <>
        {/* <Box sx={{ width: "1440px", minHeight: 100 }}> */}
        {/* <Typography
              sx={{
                color: Colors.textGrey,
                pb: { xs: "16px", lg: "0" },
                pt: { xs: "30px", lg: "75px" },
                fontSize: { xs: "28px", lg: "48px" },
                fontFamily: FontFamilyName.BasisGrotesquePro,
                fontWeight: { xs: 500, lg: 400 },
              }}
            >
              Our Product
            </Typography> */}
        {/* <Container maxWidth={"xl"}> */}
        <Masonry
          columns={{ xs: 1, lg: 1 }}
          spacing={6}
          // sx={{ backgroundColor: Colors.primaryRed }}
        >
          <SizeBoxHideonxs height={"189px"} isMobile={isMobile} />

          <SizeBoxHideonxs height={"97px"} isMobile={isMobile} />
          <ImageBuilder />
        </Masonry>
        {/* </Box> */}

        <Box
          sx={{
            height: { xs: "39px", lg: "97px" },
          }}
        />

        <Box
          sx={{
            height: { xs: "4px", lg: "34px" },
          }}
        />

        {/* </Container> */}
      </>
    );
  };

  const SliderBuilder = () => {
    return (
      <>
        <Slider {...settings}>
          {data?.imageSlide?.map((item, index) => (
            <Box
              key={index}
              component="img"
              alt={item.alt}
              // src={data?.imageSlider[0].baseUrl}
              src={ZuluConfig.baseUrlImage + item.baseUrl}
              sx={{
                objectFit: "cover",
                backgroundRepeat: "no-repeat",
                height: { xs: "176px", lg: "600px" },
                width: {
                  xs: "311px",
                  lg: "1075px",
                },
                pr: { xs: "10px", lg: "20px" },
                // backgroundColor: Colors.primaryRed,
              }}
              // width={"475px"}
            />
          ))}
        </Slider>
      </>
    );
  };

  // const SliderBuilderDiscover = () => {
  //   var _allProduct: ProductInterface[] = [];
  //   for (let index = 0; index < allProduct?.length; index++) {
  //     const element = allProduct[index];
  //     if (element.productName.toPathUrl() !== data?.productName.toPathUrl()) {
  //       _allProduct.push(element);
  //     }
  //     if (_allProduct.length === 3) {
  //       index = allProduct?.length;
  //     }
  //   }
  //   return (
  //     <>
  //       <Box
  //         sx={{
  //           height: { xs: "25px", lg: "69px" },
  //         }}
  //       />

  //       <Typography
  //         sx={{
  //           color: Colors.textGrey,
  //           fontFamily: FontFamilyName.BasisGrotesquePro,
  //           fontSize: { xs: "24px", lg: "48px" },
  //           fontWeight: 400,
  //           pl: { xs: "21px", lg: "70px" },
  //           lineHeight: { xs: "28px", lg: "56.59px" },
  //         }}
  //       >
  //         Discover More Projects
  //       </Typography>

  //       <Box
  //         sx={{
  //           height: { xs: "10px", lg: "51px" },
  //         }}
  //       />

  //       {/* <Box
  //         sx={{
  //           pl: { xs: "22px", xl: "71px" },
  //           pr: { xs: "22px", xl: "71px" },
  //         }}
  //       > */}
  //       <Slider>
  //         <Box
  //           // height={"50px"}
  //           sx={{
  //             backgroundColor: Colors.infoBlack,
  //             width: { xs: "22px", xl: "71px" },
  //             // height: "50px",
  //           }}
  //         />

  //         {_allProduct?.map((item, index) => (
  //           <CardDiscover
  //             image={[item.image]}
  //             onTap={() => {
  //               navigate("/product/" + item.productName.toPathUrl());
  //             }}
  //             title={item.productName}
  //           />
  //         ))}

  //         <>
  //           <Box
  //             // height={"50px"}
  //             sx={{
  //               // backgroundColor: Colors.infoBlack,
  //               width: { xs: "22px", xl: "71px" },
  //               // height: "50px",
  //             }}
  //           />
  //         </>
  //       </Slider>
  //       {/* </Box> */}
  //     </>
  //   );
  // };

  const ImageBuilder = () => {
    //   TODO: ada error di sini
    //         var heightSizeBox: string | null[];
    //   for (let index = 0; index < data?.image?.length; index++) {
    //     const element = data?.image[index];
    //     heightSizeBox.push("asdad");
    //   }

    // console.log(sizeImage);

    //   getImageSize()
    return (
      <>
        {data?.image?.map((item, index) => (
          <>
            <Box
              sx={{
                width: { xs: "80vw", lg: index % 2 === 0 ? "475px" : "711px" },

                // backgroundColor: Colors.primaryRed,
              }}
            >
              <Box
                component="img"
                alt={item.alt}
                src={item.baseUrl}
                sx={{
                  // backgroundColor:Colors.primaryRed,
                  objectFit: "cover",
                  backgroundRepeat: "no-repeat",
                  height: { xs: "245px", lg: "550px" },
                  width: {
                    xs: "100%",
                    lg: "100%", //index % 2 === 0 ? "475px" : "711px",
                  },
                }}
                // width={"475px"}
              />
            </Box>
            {/* </Box> */}
            <SizeBoxHideonxs
              height={"250px"}
              isMobile={isMobile}
              // color={Colors.primaryRed}
            />
          </>
        ))}
      </>
    );
  };

  const DescProductDetail = () => {
    return (
      <Grid container direction={"row"} spacing={1.5}>
        <Grid item xs={12} lg={6}>
          <Typography
            sx={{
              color: Colors.textGrey,
              fontFamily: FontFamilyName.BasisGrotesquePro,
              fontSize: { xs: "24px", lg: "48px" },
              fontWeight: 400,
              maxWidth: { xs: "none", lg: "535px" },
              lineHeight: { xs: "28px", lg: "56.59px" },
              whiteSpace: "pre-wrap",
              //   backgroundColor: Colors.primaryRed,
            }}
          >
            {data?.quote}
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          height={{ xs: "30px", lg: "0px" }}
          display={{ xs: "block", lg: "none" }}
        />
        <Grid item xs={12} lg={6}>
          <Typography
            sx={{
              color: Colors.textGrey,
              fontFamily: FontFamilyName.Akkurat,
              fontSize: { xs: "11px", lg: "16px" },
              fontWeight: 400,
              width: { xs: "none", lg: "438px" },
              lineHeight: { xs: "14px", lg: "21px" },
              whiteSpace: "pre-wrap",
            }}
          >
            {data?.desc}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  // const ImageBodyProduct = () => {
  //   return (
  //     // <Container maxWidth={"xl"}>
  //     <Grid
  //       container
  //       direction={"row"}
  //       spacing={{ xs: "20px", lg: "50px" }}
  //       //   columnSpacing={"50px"}
  //       //   rowSpacing={"50px"}
  //     >
  //       {data?.imageBody.map((item, index) => (
  //         <Grid
  //           item
  //           xs={12}
  //           lg={index < 2 ? 6 : 4}
  //           key={index}
  //           textAlign={"center"}
  //         >
  //           <Box
  //             component={"img"}
  //             src={`${item.baseUrl}?w=${index < 2 ? 699 : 460}&h=${
  //               index < 2 ? 500 : 400
  //             }&fit=crop&auto=format`}
  //             srcSet={`${item.baseUrl}?w=${index < 2 ? 699 : 460}&h=${
  //               index < 2 ? 500 : 400
  //             }&fit=crop&auto=format&dpr=2 2x`}
  //             alt={item.alt}
  //             // bgcolor={index % 2 === 0 ? Colors.primaryRed : Colors.infoBlack}
  //             sx={{
  //               objectFit: "cover",

  //               backgroundRepeat: "no-repeat",

  //               height: { xs: "70vw", lg: index < 2 ? "500px" : "400px" },
  //               width: { xs: "90vw", lg: index < 2 ? "699px" : "460px" },

  //               //   width: { },
  //               //   height: { xs: "245px", lg: index < 2 ? "500px" : "400px" },
  //             }}
  //             // height={{ xs: "245px", lg: index < 2 ? "500px" : "400px" }}
  //             // width={{ xs: "311px", lg: index < 2 ? "auto" : "auto" }}
  //           />
  //         </Grid>
  //       ))}
  //     </Grid>
  //     // </Container>
  //   );
  // };

  return (
    <>
      {/* <Container maxWidth={"xl"} sx={{ px: { xs: "22px", lg: "71px" } }}> */}
      <Box
        sx={{
          pt: { xs: "22px", lg: "107px" },
          px: { xs: "22px", lg: "71px" },
          // pr: { xs: "22px", lg: "90px" },
          //   maxWidth: "1440px",
          //   backgroundColor: Colors.infoBlack,
        }}
      >
        <Link
          component={NavLink}
          to={"/product"}
          variant="button"
          color={Colors.textGrey}
        >
          <Typography
            sx={{
              color: Colors.textGrey,
              fontFamily: FontFamilyName.Akkurat,
              fontSize: { xs: "11px", lg: "16px" },
              fontWeight: 400,
            }}
          >
            Product
          </Typography>
        </Link>

        <Box
          sx={{
            height: { xs: "8px", lg: "14px" },
          }}
        />

        <Box
        // sx={{ backgroundColor: Colors.primaryRed }}
        >
          <Typography
            sx={{
              color: Colors.textGrey,
              fontFamily: FontFamilyName.BasisGrotesquePro,
              fontSize: { xs: "28px", lg: "72px" },
              fontWeight: 500,
              maxWidth: { xs: "none", lg: "457px" },
              lineHeight: { xs: "auto", lg: "75px" },
              whiteSpace: "pre-wrap",
              // border: 1,
              //   backgroundColor: Colors.primaryRed,
            }}
          >
            {data?.productName}
          </Typography>
        </Box>
      </Box>
      <Box height={{ xs: "30px", lg: "80px" }} />
      {/* </Container> */}
      <SliderBuilder />
      {/* <Container
        maxWidth={"xl"}
        sx={
          {
            //   px: { xs: "22px", lg: "71px" }
          }
        }
      > */}
      {/* <Box component={"img"} onLoad={} /> */}
      <Box
        sx={{
          pt: { xs: "22px", lg: "107px" },
          px: { xs: "22px", lg: "71px" },
          // pr: { xs: "22px", lg: "90px" },
          //   maxWidth: "1440px",
          //   backgroundColor: Colors.infoBlack,
        }}
      >
        <DescProductDetail />

        {/* <DetailProject /> */}
      </Box>{" "}
      {/* </Container> */}
      <Box sx={{ height: { xs: "19px", lg: "98px" } }} />
      <ImageProductDetail dataImage={data?.image ?? []} />
      {/* <ImageBodyProduct /> */}
      {/* <Container
        maxWidth={"xl"}
        //   sx={{
        //       px: { xs: "22px", lg: "71px" }
        //   }}
      > */}
      {/* <Box sx={{ height: { xs: "19px", lg: "67px" } }} /> */}
      {/* <Box sx={{ px: { xs: "22px", lg: "71px" } }}>
        <Credits data={props.data?.credits} />
      </Box> */}
      {/* <Credits /> */}
      {/* </Container> */}
      <Box sx={{ height: { xs: "19px", lg: "118px" } }} />
      <Divider variant="fullWidth" orientation="horizontal" />
      {/* <SliderBuilderDiscover /> */}
      {/* <Box sx={{ height: { xs: "24px", lg: "79px" } }} /> */}
    </>
  );
};

// async function fetchImageSize(url: string): Promise<string | null> {
//   try {
//     const dimensions = await getImageSize(url);
//     console.log(dimensions);
//     return dimensions.height.toString() + "px";
//   } catch (error) {
//     console.error(error);
//     return null;
//   }
// }

BodyProductDetail.prototype = {
  data: PropTypes.objectOf<ProductDetailInterface2>,
  allProduct: PropTypes.arrayOf<ProductInterface2>,
};

export default BodyProductDetail;
