import { Box, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Colors, FontFamilyName } from "common/const/const_config";
import ContactPerson from "./components/contactperson";
import ContactForm from "./components/contactform";
import ContactFormMobile from "./components/contactformmobile";
import { ApiController } from "common/api/api_controller";
import { useEffect, useState } from "react";
import { ContactPersonInterface } from "model/interfaces/contact_person";
const Contact = () => {
  const [data, setData] = useState<ContactPersonInterface | null>(null);
  const [isLoading, setLoading] = useState(false);

  async function getData() {
    setLoading(true);
    let respon = await ApiController.getContact();

    setData({ ...respon });
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setLoading(false);
    // console.log(data);
  }, [data]);
  return (
    <>
      {/* <Header />
      <SpaceingAppBar /> */}

      <Box sx={{ height: { xs: "40px", lg: "142px" } }} />
      <Grid container direction="row" sx={{ px: { xs: "21px", md: "71px" } }}>
        <Grid item xs={12} md={6}>
          <Typography
            sx={{
              fontFamily: FontFamilyName.BasisGrotesquePro,
              color: Colors.primaryRed,
              fontSize: { xs: "28px", md: "72px" },
              fontWeight: 500,
              // pl: { xs: "21px", md: "21px" },
            }}
          >
            Contact
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          height={"30px"}
          display={{ xs: "block", md: "none" }}
        />
        <Grid item xs={12} md={6} display={{ xs: "none", md: "block" }}></Grid>
        <Grid item xs={12} md={6} display={{ xs: "none", md: "block" }}></Grid>
        <Grid item xs={12} md={6}>
          <Typography
            sx={{
              fontFamily: FontFamilyName.BasisGrotesquePro,
              color: Colors.textGrey,
              fontSize: { xs: "20px", md: "32px" },
              fontWeight: 400,
              lineHeight: { xs: "30px", md: "37.73px" },
            }}
          >
            {data?.desc ??
              `We believe in the power of collaboration and would love to
          collaborate with brands that feel the same. Let’s connect and work
          together!`}
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          height={"30px"}
          display={{ xs: "block", md: "none" }}
        />

        <Grid item xs={12} display={{ xs: "block", md: "none" }}>
          <Box height={{ xs: "10px", md: "58px" }} />
          <ContactFormMobile />
          <Box height={{ xs: "10px", md: "58px" }} />
        </Grid>

        <Grid
          item
          xs={12}
          height={"50px"}
          display={{ xs: "block", md: "none" }}
        />

        <Grid item xs={12} md={6}>
          <Box height={{ xs: "20px", md: "58px" }} />
          <ContactPerson data={data ?? null} />
        </Grid>
        <Grid item xs={12} md={6} display={{ xs: "none", md: "block" }}>
          <Box height={{ xs: "10px", md: "58px" }} />
          <ContactForm />
        </Grid>
      </Grid>
      {/* <Box height={{ xs: "20px", md: "40px" }}></Box> */}
      {/* <Footer /> */}
    </>
  );
};

export default Contact;
